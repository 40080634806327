// useLocalStorage.js
import { useState, useEffect } from 'react';

export function useLocalStorage(key, initialValue) {
  // Retrieve data from local storage when the component mounts
  const storedValue = JSON.parse(localStorage.getItem(key)) || initialValue;

  const [value, setValue] = useState(storedValue);

  useEffect(() => {
    // Update local storage whenever the value changes
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
