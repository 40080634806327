import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const DailyExpensesChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const today = new Date();
//    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const daysInMonth = lastDay.getDate();

    const dailyExpenses = new Array(daysInMonth).fill(0);

    data.forEach((expense) => {
      const expenseDate = new Date(expense.date);
      const dayOfMonth = expenseDate.getDate() - 1;
      dailyExpenses[dayOfMonth] += expense.amount;
    });

    const dates = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      const chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: dates,
          datasets: [
            {
              label: 'Gastos Diários',
              data: dailyExpenses,
              borderColor: 'rgba(255, 112, 67)', // Line color
              pointBackgroundColor: 'rgba(255, 112, 67)', // Point color
              pointBorderColor: 'rgba(255, 112, 67)', // Point border color
              borderWidth: 2,
              pointRadius: 1, // Size of points
              pointHoverRadius: 6, // Size of points on hover
              backgroundColor: 'rgba(255, 112, 67, 0.2)', // Set the fill color
            },
          ],
        },
        options: {
          responsive: true, // Set to true or false as needed
          maintainAspectRatio: true, // Set to true or false as needed
          scales: {
            y: {
              display: false, // Hide the y-axis labels
            },
          },
            x: {
              beginAtZero: true,
              grid: {
                display: false,
              },
            },  
          plugins: {
            legend: {
              display: false, // Hide the legend label and color rectangle
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  return `Day ${context.label}: R$ ${context.parsed.y}`;
                },
              },
            },
          },
          elements: {
            line: {
              fill: true,
            },
          },
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [data]);

  return (
    <div className="daily-expenses-card">
      <div>
        <h5 className="card-title">Gastos Diários</h5>
        <div className="chart-container">
          <canvas 
            ref={chartRef}
            />
        </div>
      </div>
    </div>
  );
};

export default DailyExpensesChart;
