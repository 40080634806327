import React, { useEffect, useState, useCallback } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-regular-svg-icons';
import './AppLight.css';
import './AppDark.css';
import DailyExpensesChart from './DailyExpensesChart'; // Import the DailyExpensesChart component


function App() {

 // const [isDarkMode, setIsDarkMode] = useState(false); // Default to dark mode
  const [isDarkMode, setIsDarkMode] = useLocalStorage('theme', false); // Use local storage to store the theme preference

  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  // Initialize expenses and income using the useLocalStorage hook
  const [expenses, setExpenses] = useLocalStorage('expenses', []);
  const [income, setIncome] = useLocalStorage('income', 0);
  const [incomeInput, setIncomeInput] = useState('');
  const [expenseInput, setExpenseInput] = useState('');
  const [showIncomeForm, setShowIncomeForm] = useState(false);
  const [showExpenseForm, setShowExpenseForm] = useState(false);

  const formatNumberWithCommas = (value) => {
    // Remove all non-digit characters and convert to a string
    const stringValue = String(value).replace(/[^0-9]/g, '');
  
    // Check if the value has more than two digits
    if (stringValue.length > 2) {
      const integerPart = stringValue.slice(0, -2);
      const decimalPart = stringValue.slice(-2);
      const formattedValue = `${integerPart},${decimalPart}`;
      return formattedValue;
    }
  
    // If there are one or two digits, no comma is added before the last two
    return stringValue;
  };

  // Function to calculate monthly expenses
  const calculateMonthlyExpenses = () => {
    let monthlyTotal = 0;

    expenses.forEach((expense) => {
      monthlyTotal += expense.amount;
    });

    return monthlyTotal.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  // Function to add income
  const addIncome = () => {
    const formattedIncomeValue = incomeInput.replace(',', '.');
    if (!isNaN(formattedIncomeValue) && parseFloat(formattedIncomeValue) >= 0) {
      setIncome(income + parseFloat(formattedIncomeValue));
      setIncomeInput('');
      setShowIncomeForm(false);
    }
  };

  // Function to add expense
  const addExpense = () => {
    const formattedExpenseValue = expenseInput.replace(',', '.');
    if (!isNaN(formattedExpenseValue) && parseFloat(formattedExpenseValue) >= 0) {
      const newExpense = {
        amount: parseFloat(formattedExpenseValue),
        date: new Date(),
      };
      setExpenses([...expenses, newExpense]);
      setIncome(income - newExpense.amount);
      setExpenseInput('');
      setShowExpenseForm(false);
    }
  };

// Function to reset Monthly Expenses and Income on the first day of each month
const resetMonthlyValues = useCallback(() => {
  const currentDate = new Date();
  if (currentDate.getDate() === 1) {
    setExpenses([]);
    setIncome(0);
  }
}, [setExpenses, setIncome]);

// Use useEffect to call the resetMonthlyValues function on component mount
useEffect(() => {
  resetMonthlyValues();
}, [resetMonthlyValues]);
  

  useEffect(() => {
    // Get a reference to the <body> element
    const body = document.getElementById('app-body');

    // Set the background color based on the theme
    if (isDarkMode) {
      body.style.backgroundColor = '#000000'; // Dark mode background color
    } else {
      body.style.backgroundColor = '#FFFFFF'; // Light mode background color
    }
  }, [isDarkMode]);


  // Function to get the name of the current month in Portuguese
  const getCurrentMonthName = () => {
    const months = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    const currentDate = new Date();
    return `Gastos ${months[currentDate.getMonth()]}`;
  };

  // Function to calculate expenses of the latest 3 months
  const calculateLastMonthsExpenses = () => {
    const currentDate = new Date();
    const lastThreeMonths = [];

    // Loop through the last 3 months and collect expenses
    for (let i = 1; i < 4; i++) {
      const lastMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      const lastMonthExpenses = [];

      expenses.forEach((expense) => {
        const expenseDate = new Date(expense.date);
        if (
          expenseDate >= lastMonth &&
          expenseDate <
            new Date(currentDate.getFullYear(), currentDate.getMonth() - i + 1, 1)
        ) {
          lastMonthExpenses.push(expense.amount);
        }
      });

      const formattedExpenses = lastMonthExpenses
        .reduce((acc, cur) => acc + cur, 0)
        .toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });

      lastThreeMonths.push({
        month: lastMonth.toLocaleString('default', { month: 'long' }), // Get the month name
        expenses: formattedExpenses,
      });
    }

    return lastThreeMonths;
  };


  return (
    <div className={`container ${isDarkMode ? 'background-dark' : 'background-light'}`}>
    <div className="center-content">
      <div className={isDarkMode ? 'top-bar-dark' : 'top-bar-light'}>
        <div className={isDarkMode ? 'mode-toggle-button-container-dark' : 'mode-toggle-button-container-light'}>
        <button className={isDarkMode ? 'mode-toggle-button-dark' : 'mode-toggle-button-light'} onClick={toggleMode}> {isDarkMode ? " " : " "}
        <FontAwesomeIcon icon={isDarkMode ? faMoon : faSun} />
        </button>
        </div>
        <h1 className={isDarkMode ? 'app-title-dark' : 'app-title-light'} >Finanças</h1>
      </div>

        {/* Income Card */}
        <div
          className="card text-center custom-card income-card"
          style={{
            backgroundColor: 'rgba(76, 174, 79, 0.12)',
            border: 'none',
            color: '#66BB6A',
            borderRadius: '36px',
            height: '110px',
            paddingTop: '0px',
            marginTop: '180px', // adjust margin from the top of the page for the Income Card
          }}
        >
          <div className="card-body">
            <h5 className="card-title" style={{ color: '#66BB6A' }}>
              Saldo
            </h5>
            <p className="card-text" style={{ color: '#66BB6A' }}>
              <strong>
                {income.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </strong>
            </p>
          </div>
        </div>

        {/* Monthly Expenses Card */}
        <div
          className="card text-center custom-card monthly-expenses-card"
          style={{
            backgroundColor: 'rgba(255, 87, 36, 0.12)',
            border: 'none',
            color: '#FF7043',
            borderRadius: '36px',
            height: '110px',
            paddingTop: '0px',
            
          }}
        >
          <div className="card-body">
            <h5 className="card-title" style={{ color: '#FF7043' }}>
              {getCurrentMonthName()}
            </h5>
            <p className="card-text" style={{ color: '#FF7043' }}>
              <strong>{calculateMonthlyExpenses()}</strong>
            </p>
          </div>
        </div>

         {/* Last Months Expenses Card */}
        {/* <div
          className="card text-center custom-card last-3-months-expenses-card"
          style={{
            backgroundColor: 'rgba(255, 87, 36, 0.12)',
            border: 'none',
            color: '#FF7043',
            height: '140px',
            borderRadius: '36px',
            paddingTop: '0px',
          }}
        >
          <div className="card-body">
            <h5 className="card-title">Meses Anteriores</h5>
            <ul className="list-unstyled">
              {calculateLastMonthsExpenses().map((monthExpenses, index) => (
                <li key={index}>
                  {monthExpenses.month}: <strong>{monthExpenses.expenses}</strong>
                </li>
              ))}
            </ul>
          </div>
        </div> */}

        {/* Graphics Card */}
        <div
          className="text-center daily-expenses-card"
          style={{
            backgroundColor: 'rgba(255, 87, 36, 0.12)',
            border: 'none',
            color: '#FF7043',
            height: '190px',
            borderRadius: '36px',
            paddingTop: '14px',
          }}          
          >
          <div>
            <DailyExpensesChart data={expenses} /> {/* Pass expenses data as a prop */}
          </div>
        </div>        
      </div>


    {/* Popup container for adding income */}
    {showIncomeForm && (
      <div className="popup-container">
        <div className={isDarkMode ? 'popup-dark' : 'popup-light'}>
          <h2>Adicionar Receita</h2>
          <input
            type="text"
            placeholder="Inserir"
            value={incomeInput}
            onChange={(e) => setIncomeInput(formatNumberWithCommas(e.target.value))}
            inputMode="numeric"
            className={isDarkMode ? 'custom-input-dark' : 'custom-input-light'}
          />
          <div className="button-container">
          <button className="cancel-button" onClick={() => setShowIncomeForm(false)}>
              Cancelar
            </button>
            <button className="submit-button" onClick={addIncome}>
              Adicionar
            </button>
          </div>
        </div>
      </div>
    )}

    {/* Popup container for adding expense */}
    {showExpenseForm && (
      <div className="popup-container">
        <div className={isDarkMode ? 'popup-dark' : 'popup-light'}>
          <h2>Adicionar Despesa</h2>
          <input
            type="text"
            placeholder="Inserir"
            value={expenseInput}
            onChange={(e) => setExpenseInput(formatNumberWithCommas(e.target.value))}
            inputMode="numeric"
            className={isDarkMode ? 'custom-input-dark' : 'custom-input-light'}
          />
          <div className="button-container">
          <button className="cancel-button" onClick={() => setShowExpenseForm(false)}>
              Cancelar
            </button>
            <button className="submit-button" onClick={addExpense}>
              Adicionar
            </button>
          </div>
        </div>
      </div>
    )}
    
      {/* Bottom Navbar with centered and spaced buttons */}
      <nav className={`navbar fixed-bottom justify-content-center ${isDarkMode ? 'navbar-dark' : 'navbar-light'}`}>
        <button
          className="btn btn-success btn-lg rounded-pill"
          style={{ width: '150px', backgroundColor: '#66BB6A', color: 'white', border: 'none' }}
          onClick={() => setShowIncomeForm(true)}
        >
          Receitas
        </button>

        <div className="mx-2"></div>

        <button
          className="btn btn-primary btn-lg rounded-pill"
          style={{ width: '150px', backgroundColor: '#FF7043', color: 'white', border: 'none' }}
          onClick={() => setShowExpenseForm(true)}
        >
          Despesas
        </button>
      </nav>
    </div>
  );
}

export default App;
